














































































































































































































import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";
import {Select, Option} from "element-ui";
import { Order as OrderApi } from "@/services/SOLO";

const ConceptDangerZoneProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptDangerZone extends ConceptDangerZoneProps {
  translate!: Function;
  public getLocale: any;
  imageTypes: Array<any> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload IMG",
    },
  ];
  idDisplay: Array<any> = [
    {
      value: '{id}',
      text: "Full Online Order ID",
    },
    {
      value: '{sequence}',
      text: "POS Branch Sequence Number",
    },
    {
      value: '{id}-{sequence}',
      text: "Sequence Number and Online Order ID",
    },
    {
      value: '{code}',
      text: "Full POS ID",
    },
    {
      value: '{right(id, 4)}',
      text: "Last 4 Digits of the Online Order ID",
    },
  ]
  radioTypes: Array<any> = [
    {
      value: 4,
      text: "4 Digits",
    },
    {
      value: 6,
      text: "6 Digits",
    },
    {
      value: 8,
      text: "8 Digits",
    },
  ];
  priceCalc: Array<any> = [
    {
      value: 'modifier-based',
      text: "Modifier Based",
    },
    {
      value: 'item-based',
      text: "Item Based",
    },
  ];
  vatType: Array<any> = [
    {
      value: 'inclusive',
      text: "Inclusive",
    },
    {
      value: 'exclusive',
      text: "Exclusive",
    },
  ];
  statuses: any = []

  @Watch('data', { immediate: true })
  onChangeDataFromProps(newVal: any) {
    console.log('onChangeDataFromProps', newVal);
  }

  mounted() {
    console.log('DATA From PROPS', this.data);
    this.getActiveOrderStatuses();
  }


  async getActiveOrderStatuses() {
     await OrderApi.activeStatusList()
      .then((response: any) => (this.statuses = response.data.data));
  }


}
